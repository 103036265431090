import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import LazyLoad from 'vanilla-lazyload';
import * as basicScroll from 'basicscroll';
import toggleSwitch from '../components/toggleSwitch';
import 'particles.js/particles';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Controller, Autoplay } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Controller, Autoplay]);

export default {
    init() {
        // JavaScript to be fired on all pages

        new LazyLoad({
            elements_selector: '.lazyload',
        });

        window.klaro = Klaro;
        Klaro.setup(window.klaroConfig);

        const langSwitchCheck = document.querySelectorAll('.toggle-switch--lang');
        if (langSwitchCheck.length > 0) {
            const langSwitch = new toggleSwitch('.toggle-switch--lang');

            langSwitch.element.addEventListener('change', e => {
                console.log('New language: ', e.detail);
            });
        }

        const swiperAccreditations = new Swiper('.swiper-accreditations', {
            slidesPerView: 1,
            spaceBetween: 48,
            loop: false,
            autoplay: {
                delay: 3000,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2, // 540px / 2 = 270px
                },
                768: {
                    slidesPerView: 3, // 720px / 3 = 240px
                },
                1200: {
                    slidesPerView: 4, // 1140px / 4 = 285px
                },
            },
        })
        swiperAccreditations;

        const swiperPartnersShowcase = new Swiper('.partners-showcase__slider', {
            slidesPerView: 1,
            spaceBetween: 48,
            loop: false,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: '.swiper-partners-showcase-pagination',
                type: 'bullets',
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 56,
                },
                1400: {
                    slidesPerView: 4,
                },
            },
        })
        swiperPartnersShowcase;

        let swiperPartners = {};
        const swiperPartnersElements = document.querySelectorAll('.swiper-partners');

        for (let i = 0; i < swiperPartnersElements.length; i++) {
            swiperPartnersElements[i].classList.add('swiper-partners--' + i);

            swiperPartners[i] = new Swiper('.swiper-partners--' + i, {
                slidesPerView: 1,
                spaceBetween: 48,
                loop: false,
                autoplay: {
                    delay: 3000,
                },
                pagination: {
                    el: '.swiper-partners-pagination',
                    type: 'bullets',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2, // 720px / 2 = 360px
                    },
                    992: {
                        slidesPerView: 3, // 960px / 3 = 320px
                    },
                    1400: {
                        slidesPerView: 4, // 1320px / 4 = 330px
                    },
                },
            });
        }

        const swiperHighlights = new Swiper('.swiper-highlights', {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: '.swiper-highlights-button--next',
                prevEl: '.swiper-highlights-button--prev',
            },
        })
        swiperHighlights;

        let swiperExpectCopy = new Swiper('.swiper-expect-copy', {})
        let swiperExpect = new Swiper('.swiper-expect', {
            spaceBetween: 40,
            autoplay: {
                delay: 2500,
            },
            breakpoints: {
                576: {
                    autoplay: false,
                },
                768: {
                    spaceBetween: 48,
                },
            },
            navigation: {
                nextEl: '.swiper-expect-next',
                prevEl: '.swiper-expect-prev',
            },
        })
        swiperExpect.controller.control = swiperExpectCopy;
        swiperExpectCopy.controller.control = swiperExpect;

        let words = document.querySelectorAll('.expect__group-word');

        if (words.length > 0) {
            words.forEach(function(element) {
                element.addEventListener('click', e => {
                    if (!e.target.classList.contains('expect__group-word--active')) {
                        Array.from(document.querySelectorAll('.expect__group-word')).forEach(el => {
                            el.classList.remove('expect__group-word--active')
                        });
                        e.target.classList.add('expect__group-word--active');
                    }
                });
            });
        }

        document.querySelectorAll('.expect__group-word').forEach(function(button) {
            button.addEventListener('click', function(e) {
                e.preventDefault();

                let slideNumber = button.getAttribute('data-slide');
                swiperExpectCopy.slideTo(slideNumber, 1000);
            });
        });

        // Add carets to menu
        $('.nav--main li.menu-item-has-children > a').append('<svg xmlns="http://www.w3.org/2000/svg" width="19.531" height="11.887" viewBox="0 0 19.531 11.887"><path d="M2.121,2.117l7.644,7.644,7.644-7.644" fill="none" stroke="#d5112a" stroke-linecap="round" stroke-width="3"/></svg>');

        // Opening the mobile menu
        $('#menu-button').on('click', function () {
            $('#menu-button').toggleClass('open');
            $( 'body' ).toggleClass( 'mobile-menu-open' );

            var text = $('#menu-button').attr('aria-label');
            if(text == 'Open mobile navigation menu') {
                $('#menu-button').attr('aria-label', 'Close mobile navigation menu')
                $('#menu-button').attr('aria-expanded', true)
            } else {
                $('#menu-button').attr('aria-label', 'Open mobile navigation menu')
                $('#menu-button').attr('aria-expanded', false)
            }
        });

        // Opening the header menu dropdowns
        $('nav .menu-item-has-children > a').on('click', function (event) {
            event.preventDefault();
            if( $(this).siblings('ul.sub-menu').find('.mobmenu-back').length === 0 ) {
                $(this).siblings('ul.sub-menu').prepend('<span class="mobmenu-back">Back a Level</span>');
            }
            $(this).parent().toggleClass('show');
            $(this).next().toggleClass('show');
        });

        $('nav').on('click', '.mobmenu-back', function () {
            $(this).parent().toggleClass('show');
            $(this).next().toggleClass('show');

            $(this).closest('a.dropdown-toggle').toggleClass('show');
            $(this).closest('ul.dropdown-menu').toggleClass('show');
        });

        // Parallax Images
        let parallax_sections = document.querySelectorAll('.parallax-img');
        let parallaxFrom,
            parallaxTo;

        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        if (viewportWidth > 768) {
            parallaxFrom = '-100px';
            parallaxTo = '100px';
        } else {
            parallaxFrom = '-50px';
            parallaxTo = '50px';
        }

        for (let parallax_section of parallax_sections) {
            let instance = basicScroll.create({
                elem: parallax_section,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--ty': {
                        from: parallaxFrom,
                        to: parallaxTo,
                    },
                },
            })
            instance.start();
        }

        // Detect elements coming into view and add class
        const ivElements = document.querySelectorAll('.iv');

        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('iv-loaded');
                }
            });
        }, { threshold: [0.5] });

        ivElements.forEach(function(image) {
            observer.observe(image);
        });

        // Play video when poster is clicked
        const videos = document.querySelectorAll('video');

        if (videos.length > 0) {
            videos.forEach(video => {
                video.addEventListener('click', function() {
                    video.setAttribute('controls', true);
                    setTimeout(function() {
                        video.play();
                    }, 100)
                }, false);
            });
        }

        // Show more custom speakers
        const moreSpeakersBtn = document.getElementById('js-show-more-custom-speakers');
        const speakersContainer = document.getElementById('js-custom-speakers-container');

        if (moreSpeakersBtn) {
            moreSpeakersBtn.addEventListener('click', () => {
                speakersContainer.classList.remove('hidden-items');
                moreSpeakersBtn.classList.add('d-none');
            });
        }

        // Scrolling Values
        const scrollingValues = document.querySelectorAll('.js-scrolling-values');

        if (scrollingValues.length > 0) {
            const scollingValuesObserver = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.intersectionRatio > 0) {
                        for (let i = 0; i < scrollingValues.length; i++) {
                            if (!scrollingValues[i].classList.contains('complete') && !isNaN(scrollingValues[i].innerHTML)) {
                                animateValue(scrollingValues[i], 0, scrollingValues[i].innerHTML, 1000);
                                scrollingValues[i].classList.add('complete');
                            }
                        }
                    }
                });
            }, { threshold: [0] });

            scrollingValues.forEach(function (image) {
                scollingValuesObserver.observe(image);
            });
        }

        function animateValue(obj, start, end, duration) {
            let startTimestamp = null;

            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = new Intl.NumberFormat().format(Math.floor(progress * (end - start) + start));

                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };

            window.requestAnimationFrame(step);
        }

        // don't know why this doesn't work
        // if(document.getElementById('brochure-form')) {
        //     document.addEventListener('wpcf7submit', function(event) {
        //         console.log('event.detail.contactFormId', event.detail.contactFormId);
        //         if (event.detail.contactFormId == '507' && !$('.wpcf7-form').hasClass('invalid') && $('#brochure-form').data('href') !== '') {
        //             window.open($('#brochure-form').data('href'), '_blank');
        //         }
        //     }, false );
        // }

        if(document.getElementById('brochure-form')) {
            document.getElementById('brochure-form').addEventListener('click', e => {
                if (e.target.classList.contains('wpcf7-submit') && !$('.wpcf7-form').hasClass('invalid') && $('#brochure-form').data('href') !== '') {
                    window.open($('#brochure-form').data('href'), '_blank');
                }
            });
        }

        // Agenda
        const agendaStages = document.querySelectorAll('.agenda__stage');

        if (agendaStages.length > 0) {
            showActiveAgendaMonth();
            showActiveAgendaStages();
            showActiveAgendaSessions();

            agendaStages.forEach(function(stageButton) {
                stageButton.addEventListener('click', () => {
                    if (stageButton.classList.contains('agenda__stage--active')) {
                        return;
                    }

                    document.querySelectorAll('.agenda__stage').forEach(function(stage) {
                        stage.classList.remove('agenda__stage--active');
                    });

                    stageButton.classList.add('agenda__stage--active');

                    showActiveAgendaSessions();
                });
            });
        }

        const agendaDates = document.querySelectorAll('.agenda__date');

        if (agendaDates.length > 0) {
            agendaDates.forEach(function(dateButton) {
                dateButton.addEventListener('click', () => {
                    if (dateButton.classList.contains('agenda__date--active')) {
                        return;
                    }

                    document.querySelectorAll('.agenda__date').forEach(function(date) {
                        date.classList.remove('agenda__date--active');
                    });

                    dateButton.classList.add('agenda__date--active');

                    showActiveAgendaMonth();
                    showActiveAgendaStages();
                    showActiveAgendaSessions();
                });
            });
        }

        function showActiveAgendaSessions() {
            const agendaSessions = document.querySelectorAll('.agenda__session');

            if (agendaSessions.length === 0) {
                return;
            }

            agendaSessions.forEach(function(session) {
                session.classList.remove('agenda__session--active');
                const stage = document.querySelector('.agenda__stage--active');
                const date = document.querySelector('.agenda__date--active');

                if (session.dataset.date === date.dataset.date && session.dataset.stage === stage.dataset.stage) {
                    session.classList.add('agenda__session--active');
                }
            });
        }

        function showActiveAgendaMonth() {
            const month = document.querySelector('.agenda__month');
            const newDate = document.querySelector('.agenda__date--active').dataset.date;
            const newMonth = newDate.split('-')[2];
            month.innerText = newMonth;
        }

        function showActiveAgendaStages() {
            const newDate = document.querySelector('.agenda__date--active').dataset.date;
            const newSessions = getSessionsFromDate(newDate);
            const newStages = getStagesFromSessions(newSessions);

            if (newStages.length === 0) {
                console.error('no newStages');
                return;
            }

            let addedActiveStage = false;

            agendaStages.forEach(function(stage) {
                stage.classList.remove('agenda__stage--active');
                stage.classList.remove('agenda__stage--hidden');

                if (newStages.includes(stage.dataset.stage)) {
                    if (!addedActiveStage) {
                        stage.classList.add('agenda__stage--active');
                        addedActiveStage = true;
                    }
                    return;
                }

                stage.classList.add('agenda__stage--hidden');
            });
        }

        function getSessionsFromDate(date) {
            const agendaSessions = document.querySelectorAll('.agenda__session');

            if (agendaSessions === undefined || Object.keys(agendaSessions).length === 0) {
                console.error('no agendaSessions');
                return;
            }

            let sessions = [];

            agendaSessions.forEach(function(session) {
                if (session.dataset.date === date) {
                    sessions.push(session);
                }
            });

            return sessions;
        }

        function getStagesFromSessions(sessions) {
            if (sessions.length === 0) {
                console.error('no sessions');
                return;
            }

            let stages = [];

            sessions.forEach(function(session) {
                if (!stages.includes(session.dataset.stage)) {
                    stages.push(session.dataset.stage);
                }
            });

            stages.sort();

            return stages;
        }

        function updateUTCTimes() {
            const UTCTimes = document.querySelectorAll('.UTCTime');

            if (UTCTimes.length > 0) {
                UTCTimes.forEach(timeElement => {
                    let time = new Date(timeElement.dateTime + 'Z');
                    const options = { timeStyle: 'short', hourCycle: 'h24' };
                    const localTime = time.toLocaleTimeString('en-GB', options);

                    timeElement.innerText = localTime;
                })
            }
        }

        addEventListener('load', () => {
            updateUTCTimes();
        });

        // ParticlesJS Fade
        let styleElem = document.head.appendChild(document.createElement('style'));

        function updateFadingParticlesHeight() {
            const particlesFade = document.querySelectorAll('.particles-js--fade');
            if (!particlesFade.length > 0) {
                return;
            }

            particlesFade.forEach(particles => {
                const pHeight = particles.parentElement.offsetHeight;

                if (pHeight < 3200) {
                    const newHeight = pHeight / 2;
                    const afterHeight = (pHeight / 2) - 300;
                    styleElem.innerHTML = '.particles-js--fade { max-height: ' + newHeight.toString() + 'px; } .particles-js--fade::after { top: ' + afterHeight.toString() + 'px; }';
                }
            })
        }

        updateFadingParticlesHeight()
        window.onresize = updateFadingParticlesHeight();

        // ParticlesJS
        const particlesCheck = document.querySelectorAll('.particles-js');
        if (particlesCheck.length > 0) {
            const particlesJS = window.particlesJS;
            particlesJS('particles-js', {
                'particles': {
                    'number': {
                        'value': 130,
                        'density': {
                            'enable': true,
                            'value_area': 1183.721462448409,
                        },
                    },
                    'color': {
                        'value': '#D51224',
                    },
                    'shape': {
                        'type': 'circle',
                        'stroke': {
                            'width': 0,
                            'color': '#D51224',
                        },
                        'polygon': {
                            'nb_sides': 4,
                        },
                        'image': {
                            'src': 'img/github.svg',
                            'width': 100,
                            'height': 100,
                        },
                    },
                    'opacity': {
                        'value': 0.38481889460772545,
                        'random': true,
                        'anim': {
                            'enable': false,
                            'speed': 1.5,
                            'opacity_min': 0.5,
                            'sync': false,
                        },
                    },
                    'size': {
                        'value': 8.017060304327615,
                        'random': true,
                        'anim': {
                            'enable': false,
                            'speed': 10,
                            'size_min': 0.1,
                            'sync': false,
                        },
                    },
                    'line_linked': {
                        'enable': true,
                        'distance': 150,
                        'color': '#d9d9d9',
                        'opacity': 1,
                        'width': 1,
                    },
                    'move': {
                        'enable': true,
                        'speed': 1.5,
                        'direction': 'none',
                        'random': true,
                        'straight': false,
                        'out_mode': 'out',
                        'bounce': false,
                        'attract': {
                            'enable': false,
                            'rotateX': 600,
                            'rotateY': 1200,
                        },
                    },
                },
                'interactivity': {
                    'detect_on': 'canvas',
                    'events': {
                        'onhover': {
                            'enable': true,
                            'mode': 'bubble',
                        },
                        'onclick': {
                            'enable': true,
                            'mode': 'push',
                        },
                        'resize': true,
                    },
                    'modes': {
                        'grab': {
                            'distance': 400,
                            'line_linked': {
                                'opacity': 1,
                            },
                        },
                        'bubble': {
                            'distance': 170.52652093416066,
                            'size': 6,
                            'duration': 2,
                            'opacity': 8,
                            'speed': 3,
                        },
                        'repulse': {
                            'distance': 200,
                            'duration': 0.4,
                        },
                        'push': {
                            'particles_nb': 4,
                        },
                        'remove': {
                            'particles_nb': 2,
                        },
                    },
                },
                'retina_detect': true,
            });
        }
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
